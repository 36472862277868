$(document).ready(function () {
    $('#article-9 .ce_image').matchHeight();
});

$(window).resize(function () {});

$(window).scroll(function () {
    if ($(this).scrollTop() > 50) {
        $('.scrolltop:hidden').stop(true, true).fadeIn();
    } else {
        $('.scrolltop').stop(true, true).fadeOut();
    }
});
$(function () {
    $('.scroll').click(function () {
        $('html,body').animate({
            scrollTop: $('#top').offset().top
        }, '2000');
        return false
    })
})